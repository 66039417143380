.customTooltipStyles {
    z-index: 19;
    max-width: 240px;
    padding: 25px 20px;
    border: 1px solid var(--color-black);
    border-radius: 0;

    &:before, &:after {
        position: absolute;
        top: 5px;
        right: 14px;
        left: unset;
        content: ' ';
        height: 20px;
        width: 2px;
        background-color: #333;
        cursor: pointer;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
}

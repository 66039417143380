@import 'media';
@import 'variables';

.layout {
  grid-template-rows: auto 1fr auto;
  background: var(--color-white);
  position: relative;
  min-height: 100vh;
  justify-content: center;
  grid-template-columns: 100%;
  max-width: 100vw;

  .section {
    min-height: calc(100vh - 200px - 70px - 154px - 50px);

    @include max-width($sm) {
      min-height: unset;
    }
  }

  &.test {
    background: var(--color-black);
  }

  &.isSection {
    &.test {
      background: var(--color-white);
    }

    &.onSport {
      background: var(--color-black);

      .section {
        margin: 0 auto;
        width: 1400px;
        background: var(--color-white);
      }

      @include max-width($xl) {
        .section {
          width: 100%;
        }
      }
    }

    &.blackscience {
      background: var(--color-black);
      color: var(--color-white);

      .test {
        border: 2px solid white;

        .testImage {
          border: 2px solid white;
        }
      }
    }
  }

  @include max-width($sm) {
    section {
      margin-top: 10px;
    }

    &.test,
    &.card,
    &.article,
    &.longread,
    &.blackscience,
    &.onSport {
      section {
        margin-top: 0;
      }
    }
  }
}

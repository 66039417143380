.containsList {
  padding-inline-start: 30px;
  padding-inline-end: 20px;
  display: flex;
  flex-flow: column;
  gap: 12px;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  li {
    cursor: pointer;
    padding-left: 10px;

    &:hover {
      color: var(--color-primary);
    }
  }
}

.containsList::-webkit-scrollbar {
  width: 0;
  height: 0;
}

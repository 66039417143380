@import 'media';
@import 'variables';

.input {
  border-bottom: 1px solid var(--color-gray);

  & input {
    background: transparent;
    border: none;
    padding: 0 0 10px 0;
    outline: none;
    color: var(--color-black);
    font-size: 28px;
    font-style: normal;
    line-height: 110%;
    font-weight: 600;

    &::placeholder {
      color: var(--color-gray);
    }

    &:focus::placeholder {
      color: transparent;
    }

    @include max-width($sm) {
      font-size: 18px;
      line-height: 110%;
      font-weight: 600;

      width: calc(100% - 50px);
    }
  }

  & .icon {
    cursor: pointer;

    & path,
    & circle {
      stroke: var(--color-gray);
    }
  }
}

.close {
  cursor: pointer;
  width: 32px;
  height: 32px;
  transition: all 0.2s ease-in-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  & path {
    transition: stroke;
  }

  &:hover {
    color: var(--color-primary);
  }
}

.closeHidden {
  width: 32px;
  height: 32px;
  transition: all 0.2s ease-in-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  color: transparent;
}

.blackscience,
.sport,
.test {
  .close {
    color: var(--color-white);
  }

  & input {
    color: var(--color-white);
  }
}

@import 'media';
@import 'variables';

.iframeWrapper {
  overflow: hidden;
  width: 100%;
  aspect-ratio: 16/9;

  iframe {
    height: unset;
    aspect-ratio: 16/9;
    width: 100%;
  }
}

@import 'media';
@import 'variables';

html,
body {
  padding: 0;
  margin: 0;
  font-family: Gilroy, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  -webkit-tap-highlight-color: transparent; // убирает синюю подсветку в хроме при клике на элемент в мобилке
}

body > iframe {
  z-index: 100000000 !important;
}

input {
  font: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

p {
  margin: 0;
}

:root {
  --color-white: #ffffff;
  --color-black: #151515;
  --color-black-08: #444444;
  --color-black-black: #000000;
  --color-gray: #9a9a9a;
  --color-gray-04: rgba(154, 154, 154, 0.4);

  --color-black-science: #ea3147;
  --color-black-science-light: #ea3147cc;
  --color-na-sporte: #5db64c;
  --color-na-sporte-light: #5db64ccc;

  --color-gray-dark: #3b3b3b;
  --color-gray-light: #f0f0f0;
  --color-gray-lighter: #ededf0;
  --color-gray-light-dark: #747474;

  --color-primary: #1e4fbd;
  --color-primary-light: #1e4fbdcc;

  --color-secondary: #fddc49;

  --color-social-vk: #0077ff;
  --color-social-telegram: #0088cc;
  --color-social-youtube: #ff0000;
  --color-social-facebook: #4267b2;
  --color-social-odnoklassniki: #ed812b;
  --color-social-twitter: #00acee;
  --color-social-whatsapp: #25d366;
  --color-social-tiktock: #3b3b3b;
  --color-social-dzen: #3b3b3b;

  --color-alert: #278a1e;
  --color-fail: #b71f1f;

  // ReactTooltip
  --rt-opacity: 1;
}

@font-face {
  font-family: Gilroy;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Gilroy;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Gilroy;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Gilroy;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Gilroy;
  font-weight: 700;
  font-style: normal;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide {
  width: initial !important;
}

.swiper-button-disabled {
  svg {
    cursor: not-allowed;
    opacity: 0.4;
  }
}

.two-standard-cards + .two-standard-cards {
  margin-top: 10px;
  @include max-width($sm) {
    margin-top: unset;
  }
}

.two-standard-cards + .three-small-cards {
  margin-top: 20px;
  @include max-width($sm) {
    margin-top: unset;
  }
}

@import 'media';
@import 'variables';

.widget {
  overflow: hidden;
}

.wrapper {
  text-align: center;
  position: relative;

  &.sporcle {
    aspect-ratio: unset;
    //height: 100%;

    iframe {
      width: 100%;
      //height: 100%;
    }
  }

  &.youtube {
    iframe {
      height: unset;
      aspect-ratio: 16/9;
      width: 100%;
    }

    @supports not (aspect-ratio: 16/9) {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &.wide {
    & iframe {
      margin: 0 auto;
      width: 100%;
      border-width: 0;
    }

    &.inst {
      & iframe {
        width: 540px !important;
      }
    }

    &.VK {
      & iframe {
        max-width: 100%;
      }
    }

    &.rutube,
    &.kp,
    &.googleMaps {
      & iframe {
        height: 100%;
      }
    }
  }

  &.center {
    justify-content: center;

    & iframe {
      border-width: 0;
    }

    &.VK_POST {
      justify-content: unset;
    }

    &.VK {
      & iframe {
        max-width: 100%;
        aspect-ratio: 16/9;
      }
    }

    &.kp {
      & iframe {
        height: 100%;
        max-width: 560px;
        aspect-ratio: 16/9;
      }
    }
  }

  &.fullscreen {
    &.youtube,
    &.rutube,
    &.VK,
    &.kp,
    &.googleMaps {
      & iframe {
        height: 100%;
        width: 100%;
      }
    }
  }

  &.wide,
  &.center,
  &.fullscreen {
    &.VK {
      @supports not (aspect-ratio: 16/9) {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &.inst,
  &.twitter {
    justify-content: center;
  }

  @include max-width($sm) {
    &:not(.inst),
    &.wide,
    &.center {
      justify-content: unset;

      & iframe {
        width: calc(100vw - 30px);
      }
    }

    &.wide,
    &.center,
    &.fullscreen {
      &.youtube,
      &.rutube,
      &.VK,
      &.kp,
      &.googleMaps {
        & iframe {
          width: calc(100vw - 30px);
          height: 100%;
          height: fill-available;
          height: -moz-available;
          height: -webkit-fill-available;
        }
      }
    }

    &.inst {
      &.wide,
      &.center {
        & iframe {
          width: calc(100vw - 30px) !important;
        }
      }
    }
  }
}

.cardStyles {
  @include max-width($sm) {
    &.wide,
    &.center,
    &.fullscreen {
      &.youtube,
      &.rutube,
      &.VK,
      &.kp,
      &.googleMaps {
        & iframe {
          width: 100%;
          height: 100%;
          height: fill-available;
          height: -moz-available;
          height: -webkit-fill-available;
        }
      }
    }
  }
}

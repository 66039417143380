@import 'media';
@import 'variables';

.slider {
  :global(.swiper) {
    padding: 0 calc((100% - 1200px) / 2);

    @include max-width($xl) {
      padding: 0 15px;
    }
  }
  
  :global(.swiper-wrapper) {
    height: auto;
    transition-duration: 300ms !important;
  }
}


@import 'media';

.tag {
  &:hover {
    & span.title {
      color: var(--color-primary);
    }
  }

  img {
    height: inherit;
    width: inherit;
    object-fit: cover;
  }

  .wrapper {
    border-bottom: 1px solid var(--color-gray-04);
    padding-bottom: 20px;

    @include max-width(1200px) {
      // border: 0;
      padding-bottom: 0;
    }

    @include max-width(375px) {
      // border-bottom: 1px solid var(--color-gray-04);
      padding-bottom: 10px;
    }
  }

  .date {
    @include max-width(1200px) {
      // border-bottom: 1px solid var(--color-gray-04);
    }
    @include max-width(375px) {
      // border: 0;
    }
  }
}

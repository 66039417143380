.specialProject {
  div.imageOverlay {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: var(--color-white);
      z-index: 2;
      transition: opacity 0.25s ease-out;
      opacity: 0.3;
    }
  }

  & div.imageOverlay {
    &::before {
      background: var(--color-black-black);
    }
  }
  &:hover {
    & span.title {
      color: var(--color-white);
    }
    & div.imageOverlay {
      &::before {
        background: var(--color-black-black);
        opacity: 0.4;
      }
    }
  }
}


@import 'media';
@import 'variables';

.select {
  width: inherit;
  border-radius: 17px;

  :global(.react-select__control) {
    border-radius: 17px;
    border: 1px solid var(--color-primary, #1e4fbd) !important;
    box-shadow: none;
    cursor: pointer;
  }

  :global(.react-select__multi-value__remove) {
    display: none;
  }

  :global(.react-select__option) {
    text-transform: lowercase;
    align-items: center;
    display: flex;
    gap: 6px;
    cursor: pointer;
  }

  :global(.react-select__option--is-selected) {
    background: white;
    color: var(--color-primary, #1e4fbd);
  }

  :global(.react-select__indicator-separator) {
    display: none;
  }

  :global(.react-select__indicator) {
    cursor: pointer;
    color: var(--color-primary, #1e4fbd) !important;
  }

  :global(.react-select__menu) {
    border-radius: 15px;
    border: 1px solid var(--color-primary, #1e4fbd);
    box-shadow: none;
    top: -20%;
  }
}

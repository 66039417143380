.vk {
  width: 21px;
}

.telegram {
  width: 18px;
  margin-left: -1px;

}

.twitter,
.odnoklassniki,
.whatsapp
 {
  height: 40px;
  width: 40px;
}


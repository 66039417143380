@import 'media';
@import 'variables';

.top {
  .title {
    color: var(--color-black);
  }

  .title p {
    display: inline;
    padding-right: 10px;
    box-decoration-break: clone;
  }

  div.imageOverlay {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: var(--color-white);
      z-index: 2;
      transition: opacity 0.25s ease-out;
      opacity: 0;
    }
  }

  &:hover {
    & span.title {
      color: var(--color-primary);
    }

    & div.imageOverlay {
      &::before {
        opacity: 0.1;
      }
    }
  }
}

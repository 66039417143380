.vk {
  height: 9.3px;
  width: 16px;

  &.small {
    height: 7px;
    width: 12px;
  }
}

.telegram {
  height: 12px;
  width: 13.3px;
  margin-left: -1px;

  &.small {
    height: 9px;
    width: 10px;
  }
}

.youtube {
  height: 10.6px;
  width: 16px;

  &.small {
    height: 8px;
    width: 12px;
  }
}

.dzen,
.facebook,
.tiktock,
.twitter,
.odnoklassniki
 {
  height: 32px;
  width: 32px;

  &.small {
    height: 24px;
    width: 24px;
  }
}


@import 'media';
@import 'variables';

.footer {
  background: var(--color-black);
  max-width: unset;
  margin: unset;
  width: 100vw;
  color: var(--color-white);
  padding: 35px 35px 20px 35px;
  z-index: 10000000;

  position: relative;
  left: 50%;
  right: 50%;
  margin: 0 -50vw;

  & .logo {
    width: 212px;

    & path {
      fill: var(--color-white);
    }
  }

  @include max-width($lg) {
    padding: 35px 15px 20px;
  }

  @include max-width($sm) {
    right: unset;

    & .logo {
      width: 126px;
    }
  }
}

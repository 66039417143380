.test {
  div.imageOverlay {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: var(--color-black-black);
      z-index: 2;
      transition: opacity 0.25s ease-out;
      opacity: 0;
    }
  }

  &:hover {
    & span.title {
      opacity: 0.8;
    }
    & div.imageOverlay {
      &::before {
        opacity: 0.3;
      }
    }
  }
}

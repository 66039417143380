.subsectionCard {
  div.imageOverlay {
    background: linear-gradient(180deg, rgba(84, 84, 84, 0) 50%, #000000 93%);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: var(--color-white);
      z-index: 2;
      transition: opacity 0.25s ease-out;
      opacity: 0;
    }
  }

  &:hover {
    & span.title {
      color: var(--color-primary);
    }

    & div.imageOverlay {
      &::before {
        opacity: 0.1;
      }
    }
  }

  &.blackscience,
  &.sport {
    & div.imageOverlay {
      &::before {
        background: var(--color-black-black);
      }
    }

    &:hover {
      & span.title {
        color: var(--color-black-science);
      }

      & div.imageOverlay {
        &::before {
          background: var(--color-black-black);
          opacity: 0.1;
        }
      }
    }
  }

  &.sport {
    &:hover {
      & span.title {
        color: var(--color-na-sporte);
      }
    }
  }
}
